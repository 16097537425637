import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Recaptcha from "react-recaptcha";
import { ArrowLeft, ArrowRight, Check, X } from "feather-icons-react/build/IconComponents";
import logo from "../Assets/shop-easy-black.png";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Link, useSearchParams } from 'react-router-dom';

const Register = () => {
  const [err, seterr] = useState(false);
  const [captchaVarify, setCatptcha] = useState(false);
  const [storeName, setStoreName] = useState();
  const [mailSent, setMailSent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [inputErr, setInputErr] = useState(false)
  const [bussinessCategories, setBussinessCategories] = useState([])

  const [RegData, setRegData] = useState({
    email: "",
    business_name: "",
    business_category: "",
  });
  const { email, business_name, business_category } = RegData;

  //Show And Hide Password
  const [message, setmessage] = useState("");
  // get All Entered  Input Data In our UseState

  const HandleInput = (e) => {
    if (e.target.name === "email") {
      setEmailErr(false)
    }
    setRegData({ ...RegData, [e.target.name]: e.target.value });

  };
  // Api Call for Register
  const HandleRegister = async (e) => {
    e.preventDefault()
    setEmailErr(false)
    setLoader(true)
    if (business_category !== '' && business_name !== "" && email !== "" && RegData?.business_category) {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/super/admin/tenant/emailVerifySend`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email,
            business_category,
            store_id: storeName,
            business_name,
          }),
        }
      ).then((res) => {

        if (res.status === 400 || res.status === 500) {
          toast.error("Check The Data You Entered Or Email Already Registered ");
        }
        else if (res.status === 409) {
          setEmailErr(true)
          setLoader(false)
        }
        else {
          setMailSent(true);
          setLoader(false);
        }
        setCatptcha(false)
      })
    }
    else {
      setInputErr(true)
    }

  };

  const Handleblur = () => {
    setIsVarified(false);
    seterr(false);
    let store = business_name;
    const uniqueStore = store
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace consecutive spaces with a single hyphen
      .replace(/[^a-zA-Z0-9\-]/g, "") // Remove special characters
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen;
    setStoreName(uniqueStore);
  };

  const getStoreCategories = async () => {
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/super/admin/storecategory/getStoreCategory`,
        { method: "GET", headers: { "content-type": "application/json" } }
      )
        .then((res) => res.json())
        .then((data) => {
          setBussinessCategories(data?.data?.categorys)
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStoreCategories();
  }, [])

  const storeapidata = async () => {
    try {
      if (storeName?.length >= 3) {
        const storeApiData = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/super/admin/tenant/storeIdVarify/${storeName}`,
          { method: "GET", headers: { "content-type": "application/json" } }
        );
        if (storeApiData.status === 403) {
          seterr(true);
          setIsVarified(false);
        } else if (storeApiData.status === 200 || storeApiData.status === 304) {
          seterr(false);
          setIsVarified(true);
        } else {
          toast.error("something went Wrong!", { position: "top-right" });
          console.log("error");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("something went wrong!", {
        position: "top-right",
      });
      console.log("error");
    }
  };
  //captcha
  const [isVarified, setIsVarified] = useState(false);
  const RecaptchaLoaded = () => { };
  const [searchParams] = useSearchParams();
  const store = searchParams.get('store');
  useEffect(() => {
    if(store){
      setRegData({
        ...RegData,
        business_name:store
      })
    }
  },[store])
  return (
    <>
      <div className="bg flex h-screen w-screen items-center justify-center">
        <div className="w-full form sm:w-8/12 xl:w-1/3 my-5 mx-5 bg-white p-5 sm:p-7 rounded-xl">
          <div className="mb-2">
            <img
              className="mx-auto h-6 sm:h-7 w-auto"
              src={logo}
              alt="Your Company"
            />
            {!mailSent && !loader &&
              <>
                <h2 className="mt-4 text-center text-base sm:text-xl font-medium text-gray-900">
                  Create your store
                </h2>
                <p className=" text-center text-xs sm:text-[13px] text-gray-500">
                  Your account will allow you to partner with shopeasy
                </p>
              </>}
          </div>
          <hr className="my-4 border-gray-300"/>
          {loader && (
            <div className="flex flex-col items-center justify-center">
              <div className="loader"></div>
              <span className="p-5 font-medium">We're verifying your store information. You'll receive a confirmation email shortly....</span>
            </div>
          )}

          {!mailSent && !loader ? (
            <div className="">
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="rounded-md  space-y-4">
                <div>
                  <label htmlFor="email-address" className="capitalize text-gray-600 text-[13px] mb-1 font-medium">
                    email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    value={email}
                    required
                    onChange={HandleInput}
                    className={`relative block w-full rounded border border-gray-300 outline-none focus:border-gray-500 py-2 text-gray-900 pl-4 placeholder:text-gray-400  text-sm`}
                    placeholder="Enter your email address"
                  />
                  {
                    emailErr && <h1 className="text-sm text-red-500 ps-1">Email already registered</h1>
                  }
                  {
                    inputErr && email === "" && <h1 className="text-sm text-red-500 ps-1">Email is required</h1>
                  }
                </div>
                <div>
                  <label htmlFor="Businessname" className="capitalize text-gray-600 text-[13px] mb-1 font-medium">
                    Business Name
                  </label>
                  <input
                    id="BusinessName"
                    name="business_name"
                    type="Text"
                    value={business_name}
                    onBlur={Handleblur}
                    onChange={HandleInput}
                    required
                    className="relative block w-full rounded border border-gray-300 outline-none focus:border-gray-500 py-2 text-gray-900 pl-4 placeholder:text-gray-400  text-sm"
                    placeholder="Enter your bussiness name"
                  />
                  {
                    inputErr && business_name === "" && <h1 className="text-sm text-red-500 font-medium">BusinessName is required</h1>
                  }
                </div>
                <div>
                  <label htmlFor="Store Id" className="capitalize text-gray-600 text-[13px] mb-1 font-medium">
                    Store Id
                  </label>
                  <div className="relative">
                    <input
                      id="Store Id"
                      name="storeName"
                      type="text"
                      value={storeName}
                      required
                      onChange={(e) => {
                        setStoreName(
                          e.target.value
                            .toLowerCase()
                            .replace(/\s+/g, "-") // Replace consecutive spaces with a single hyphen
                            .replace(/[^a-zA-Z0-9\-]/g, "") // Remove special characters
                            .replace(/-+/g, "-") // Replace multiple hyphens with a single hyphen;
                        );
                        setIsVarified(false);
                        seterr(false);
                      }}
                      className="relative block w-full rounded border border-gray-300 outline-none focus:border-gray-500 py-2 text-gray-900 pl-4 placeholder:text-gray-400  text-sm"
                      placeholder="Store id"
                      verifyCallback={(response) =>
                        response ? setIsVarified(true) : setIsVarified(false)
                      }
                      onBlur={() => setmessage("")}
                    />
                    {message}
                    {!err && storeName && !isVarified && storeName.length >= 3 ? (
                      <span
                        className="absolute z-10 py-1 top-[50%] -translate-y-[50%] right-3 cursor-pointer justify-end rounded bg-green-600 px-3 text-xs font-medium text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={storeapidata}
                      >
                        Verify
                      </span>
                    ) : null}
                    {err && (
                      <h1 className="absolute z-10 top-[50%] -translate-y-[50%] right-3 text-white rounded-full bg-red-600 h-[22px] w-[22px] flex items-center justify-center">
                        <X className="w-4 h-4 stroke-white" />
                      </h1>
                    )}
                    {isVarified ? (
                      <h1 className="absolute z-10 top-[50%] -translate-y-[50%] right-3 text-white rounded-full bg-green-600 h-[22px] w-[22px] flex items-center justify-center">
                        <Check className="w-4 h-4 stroke-white" />
                      </h1>
                    ) : (
                      <></>
                    )}
                  </div>
                  {storeName && storeName?.length >= 3 && !isVarified && !err ? (
                    <span className="text-red-500 inline-block text-xs">Please click on verify</span>
                  ) : (
                    <></>
                  )}
                  {storeName && storeName?.length < 3 && !isVarified && !err ? (
                    <span className="text-red-500 inline-block text-xs">Minimum 3 characters required</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <label className="capitalize text-gray-600 text-[13px] mb-1 font-medium">Business Category</label>
                  <Autocomplete
                    className="businessCategory"
                    id="size-small-standard"
                    size="small"
                    options={bussinessCategories?.length > 0 ? bussinessCategories : []}
                    getOptionLabel={(category) => category}
                    disableClearable
                    onChange={(e, v) => { setRegData({ ...RegData, business_category: v }) }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        placeholder="Select business category"
                      />
                    )}
                  />
                  {
                    inputErr && business_category === "" && <h1 className="text-sm text-red-500 font-medium">Business Category is required</h1>
                  }
                </div>
              </div>
              <div className="mt-4">
                <Recaptcha
                  className="rounded-xl"
                  sitekey="6LcG1O8lAAAAAND5E4JhN2fiTPpLF0gh-5Di8-tB"
                  render="explicit"
                  onloadCallback={RecaptchaLoaded}
                  verifyCallback={(response) =>
                    response ? setCatptcha(true) : setCatptcha(false)
                  }
                />
                {!isVarified || !captchaVarify || inputErr || RegData?.business_category === '' || RegData?.email === '' || RegData?.business_name === '' ? (
                  <>
                    <button className="mt-4 opacity-40 text-sm text-white w-full block cursor-not-allowed bg-btn">
                      Create Store
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="mt-4 text-sm text-white w-full block btn"
                      onClick={(e) => HandleRegister(e)}
                    >
                      Create Store
                    </button>
                  </>
                )}
              </div>
              <p className="mt-3 text-center w-full text-sm">
                New to shopeasy?
                <Link
                  className='cursor-pointer inline-flex items-center ms-1 text-center text-link transition-all duration-150 font-medium hover:underline  text-black'
                  to="http://docs.shopeasy.ai"
                  target="_blank"
                >
                  Get started
                  <ArrowRight className="w-3 h-3 ms-1" />
                </Link>
              </p>
            </div>
          ) : (
            !loader && (
              <div className="">
                <p className="text-center font-medium">
                  {" "}
                  Check your email inbox and verify your e-mail address
                </p>

                <button
                  className="mx-auto flex items-center border-b border-indigo-600 mt-4 text-indigo-600"
                  onClick={() => {
                    setMailSent(false);
                    setRegData({
                      email: "",
                      business_name: "",
                      business_category: "",
                    });
                    setStoreName("");
                    setIsVarified(false);
                  }}
                >
                  <ArrowLeft className="w-4 h-4 me-1" />
                  Back to store create
                </button>
              </div>
            )
          )}

        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Register;

import './App.css';
import DeclinedMes from './Components/DeclinedPage';
import PendingMes from './Components/PendingMes';
import Register from './Components/Register';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
function App() {
  return (
<BrowserRouter>
<Routes>
  <Route path='/' element={  <Register/>}/>
  <Route path='/verify/:secret' element={<PendingMes/>}/>
  <Route path='/decline-page' element={<DeclinedMes/>}/>
</Routes>
</BrowserRouter>

  );
}

export default App;

import React from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../Assets/shop-easy-black.png';
import { Check } from 'feather-icons-react/build/IconComponents';

function DeclinedMes() {
  const navigate = useNavigate();
  return (
    <>
      <div className='bg relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12 bg-white'>
        <div className="w-full sm:w-8/12 xl:w-1/3 mx-5 my-10 bg-white p-7 rounded-xl border border-gray-300">
          <div className='pb-5'>
            <img className='w-[15rem] mx-auto' src={logo} alt='logo'/>
          </div>
          <div className='max-w-xl px-5 text-center'>
            <h2 className='mb-2 text-[30px] font-bold text-red-500'>Verification Failed</h2>
            <p className='mb-2 text-base text-zinc-500'>
              We are sorry to inform you that your store request has been canceled by Shopeasy team...Or you ran out of varification time..
            </p>
            <h2 className='my-3 text-xl font-bold text-left text-red-500 '>Possible Reasons:</h2>
            <div className='flex items-center space-x-3'>
              <Check className='font-bold' />
              <h1 className='font-semibold'>In-apropriate email address..</h1>
            </div>
            <div className='flex items-center space-x-3'>
              <Check className='font-bold' />
              <h1 className='font-semibold'>In-apropriate store name ..</h1>
            </div>
            <div className='flex items-center space-x-3'>
              <Check className='font-bold' />
              <h1 className='font-semibold'>Suspecious activity...</h1>
            </div>
            <div className='flex items-center space-x-3'>
              <Check className='font-bold' />
              <h1 className='font-semibold'>Un-wanted spam I'd...</h1>
            </div>
            <p className='my-5 text-base font-medium text-green-700'>
              Please try again with valid data...Thank you for your kind Co-operation.. 
              <button className='text-blue-500 hover:underline' onClick={() => navigate('/')}>Create New Store</button>
            </p>
          </div>
        </div>
      </div>

    </>
  );
}
export default DeclinedMes;

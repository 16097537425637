import React, { useEffect, useState } from "react";
import logo from "../Assets/shop-easy-black.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify"
import { ArrowLeft, ArrowRight } from "feather-icons-react/build/IconComponents";
function PendingMes() {
  const location = useLocation();
  const navigate = useNavigate()
  const secretKey = window.location.href.split("/").pop();
  const [loader, setLoader] = useState(true)
  const [alreadyExist, setAlreadyExist] = useState(false)

  useEffect(() => {
    verifyEmail();
  }, []);
  const verifyEmail = async () => {
    await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/super/admin/tenant/emailVerify/${secretKey}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      }
    ).then((res) => {
      if (res.status === 500) {
        toast.error("Something Went Wrong !  Check The Data You Entered ");
      }
      else if (res.status === 408) {
        navigate("/decline-page")
      }
      else if (res.status === 400) {
        setAlreadyExist(true)
      }
      setLoader(false);
    });
  };
  return (
    <div className="bg">
      {
        loader ?
          (
            <div className="flex flex-col items-center justify-center h-screen">
              <div className="loader"></div>
              <span className="p-5 font-medium">Almost there! We're getting things ready for you...</span>
            </div>
          ) :

          <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12">
            <div className="pb-5">
              <img className="w-[15rem] mx-auto" alt="logo" src={logo} />
            </div>
            <div className="max-w-xl px-5 text-center">
              {
                alreadyExist ?
                  <>
                    <h2 className="mb-2 text-[30px] max-sm:text-[30px] max-sm:mb-5 font-bold text-gray-600 ">
                      Email already registered
                    </h2>
                    <button
                      className="cursor-pointer inline-flex items-center ms-1 text-center hover:text-indigo-500 transition-all duration-150 font-medium underline  text-black"
                      onClick={() => {
                        navigate('/')
                      }}
                    >
                      <ArrowLeft className="w-4 me-1 h-4" />
                      Back to create store
                    </button>
                  </>
                  :
                  <>
                    <h2 className="mb-2 text-[42px] max-sm:text-[30px] max-sm:mb-5 font-bold text-green-700 ">
                      Registration Successful
                    </h2>
                    <p className="mb-2 text-lg text-zinc-500">
                      <b className="text-xl m-2">
                        Thank you for connecting with Shopeasy...
                      </b>
                      <br />{" "}
                      <p className="font-medium text-gray-500 text-sm">
                        Please wait while we verify your store..We will inform you via
                        email on your registered email..
                      </p>
                      <span className="font-medium text-[#234857]">
                        <br></br>
                        {location.state}
                      </span>
                    </p>
                  </>

              }
            </div>
            <marquee
              className="text-gray-500 inline-block text-xl mt-10"
              width="100%"
              direction="left"
              height="100px"
            >
              <pre>
                Shopeasy.ai - AI-based e-Commerce platform to sell more
                globally! Start your own e-Commerce store within <b>30 seconds!</b>{" "}
                Shopeasy.ai - AI-based eCommerce platform to sell more
                globally! Start your own eCommerce store within <b>30 seconds!</b>{" "}
              </pre>
            </marquee>
            <p className="text-center w-full text-base">
                New to shopeasy?
                <Link
                  className='cursor-pointer inline-flex items-center ms-1 text-center hover:text-indigo-500 transition-all duration-150 font-medium underline  text-black'
                  to="http://docs.shopeasy.ai"
                  target="_blank"
                >
                  Get started
                  <ArrowRight className="w-3 h-3 ms-1" />
                </Link>
              </p>
          </div>
      }
    </div>
  );
}
export default PendingMes;
